.blog-post {
  &__hero {
    background-color: #f2f2f2;
  }

  &__main-image {
    width: 100%;
    object-fit: cover;
  }

  &__content {
    line-height: 32px;
  }
}
