.blog {
  &__item {
    background-color: #fff;
    transition: box-shadow .3s ease-in-out;

    &:hover {
      .blog__item-image {
        transform: scale(1.05);
      }
    }

    &-link {
      overflow: hidden;
      display: block;
    }

    &-image {
      transform: scale(1);

      width: 100%;
      height: auto;
      min-height: 255px;
      max-height: 255px;

      object-fit: cover;

      image-rendering: optimize-contrast;

      transition: transform 0.8s cubic-bezier(0.35, 0, 0, 1);
    }
  }

  &__fullscreen {
    position: relative;
    z-index: 1;

    overflow: hidden;

    background-color: #fff;

    transition: box-shadow .3s ease-in-out;

    &::before {
      pointer-events: none;
      content: '';

      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 50%;

      background: linear-gradient(-180deg, rgb(0 15 30 / 0%) 0%, rgb(0 15 30 / 37%) 44%, rgb(0 15 30 / 67%) 100%);
    }

    &:hover {
      .blog__fullscreen-image {
        transform: scale(1.05);
      }
    }

    &-image {
      z-index: -1;
      bottom: 0;
      transform: scale(1);

      width: 100%;
      height: 100%;

      object-fit: cover;

      image-rendering: optimize-contrast;

      transition: transform 0.8s cubic-bezier(0.35, 0, 0, 1);
    }

    &-intro {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
