.elevator-item-card {
  position: relative;

  &:hover {
    .elevator-item-card__image {
      box-shadow: 0 .5rem 1rem rgb(0 0 0 / 15%);
    }

    .elevator-item-card__link {
      background-color: $supernova;
    }
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__link {
    display: inline-block;

    padding: 5px;

    text-decoration: none;

    background-color: $white;
    border-radius: 0 0 0 .25rem;

    transition: background-color .3s ease-in-out;
  }

  &__image {
    width: 100%;
    object-fit: cover;
    image-rendering: optimize-contrast;
    transition: box-shadow .3s ease-in-out;
  }
}
