.page-header {

  @include media-breakpoint-down(md) {
    &::before {
      width: 100vw;
      border-top-right-radius: 0;
    }
  }

  position: relative;

  max-width: 100%;
  height: auto;

  background-color: #f7f7f7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__navigation {

    @include media-breakpoint-down(lg) {
      left: .5rem;
    }

    position: absolute;
    top: 1.1rem;
    left: 1rem;
  }

  &__info {
    position: relative;
    z-index: 1;
    background: #fff;
    border-top-right-radius: .25rem;

    &::before {
      content: "";

      position: absolute;
      z-index: -1;
      bottom: 0;
      left: -100%;

      width: 100%;
      height: 100%;

      background-color: #fff;
    }

    &-title {
      display: inline-block;
      border-bottom: 2px solid $supernova;
    }
  }
}
