.faq {
  position: relative;

  &__sidebar {
    top: 120px;
    overflow: hidden scroll;
    max-height: 70vh;
  }

  &__list {
    &-item {
      padding: 0;

      &-link {
        font-size: 14px;
        color: $black;
        text-decoration: none;

        &:hover {
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    vertical-align: bottom;

    &-link {
      cursor: pointer;
      margin-left: 10px;
      color: #11809f;
      text-decoration: none;

      &:hover {
        color: #ff6900;
        text-decoration: none;
      }
    }
  }
}
