.elevators {
  background-color: #f2f2f2;

  &__item {
    position: relative;

    overflow: hidden;

    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: '';

      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      left: 0;

      display: block;

      width: 100%;

      opacity: .45;
      background-color: $river-bed;
    }

    &-info {
      position: relative;
      z-index: 1;
      background-color: $white;
    }
  }

  &__nav {
    min-width: 250px;

    .active {
      background-color: #ffc600;
    }

    &-link {
      color: $black;
      background-color: $white;
    }
  }
}
