.footer {
  &__label {
    font-size: 20px;
    font-weight: bold;
  }

  &__list {
    padding: 0;
    list-style-type: none;

    &-link {
      font-size: 14px;
      color: $black;
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: underline;
      }

      &:focus {
        outline: 1px solid $black;
        outline-offset: 2px;
      }
    }
  }

  &__social {
    padding: 0;
    color: $black;
    list-style-type: none;

    &-link {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }

  &__copyright {
    &-link {
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
}
