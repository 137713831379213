.consulting {
  position: relative;

  &::before {

    @include media-breakpoint-down(md) {
      display: none;
    }

    content: '';

    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 50%;

    display: block;

    width: 50%;

    opacity: .3;
    background-color: $river-bed;
  }

  &__image {

    @include media-breakpoint-down(md) {
      display: none;
    }

    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;

    width: 50%;

    background-image: url('../img/elevator-consulting.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    image-rendering: optimize-contrast;
  }
}
