@import './node_modules/bootstrap/scss/bootstrap';

body {
  overflow-x: hidden;

  font-family: 'Open Sans', HelveticaNeue, Helvetica, Arial, sans-serif;
  color: $black;
  text-rendering: geometricprecision;

  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

.no-scroll {
  overflow: hidden;
}

.link {
  &:hover {
    color: $supernova;
    border-bottom-color: $supernova;
  }

  &:focus {
    color: $supernova;
    text-decoration: none;
    outline: 1px solid $supernova;
  }
}
