.mobile-navigation {
  -webkit-overflow-scrolling: touch;
  background-color: $white;
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 45;

  &__item {
    position: relative;

    &-list {
      display: none;
      visibility: hidden;
    }
  }
}

.mobile-menu-show {
  background-color: #f2f2f2;
  display: block;
  visibility: visible;

  li:first-child {
    position: relative;

    &:after {
      box-shadow: rgb(0 0 0 / 10%) 0 4px 15px 4px;
      content: "";
      left: 0;
      position: absolute;
      top: -1px;
      width: 100%;
    }
  }

  li:last-child {
    position: relative;

    &:after {
      bottom: -1px;
      box-shadow: rgb(0 0 0 / 10%) 0 4px 15px 4px;
      content: "";
      left: 0;
      position: absolute;
      top: inherit;
      width: 100%;
    }
  }
}
