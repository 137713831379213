/*
  Style for online chat status message (online/offline).
  Snippet: snippets/snippet__chat-status.php
*/

.online-chat-status {
  font-size: 12px;
  vertical-align: text-bottom;

  &__online {
    color: #008000;
  }

  &__offline {
    color: #dc3545;
  }
}
