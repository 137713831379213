.news-releases {
  background-color: #f2f2f2;

  &__item {
    position: relative;
    overflow: hidden;
    display: block;
    color: $white;

    &::before {
      pointer-events: none;
      content: '';

      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 50%;

      background: linear-gradient(-180deg, rgb(0 15 30 / 0%) 0%, rgb(0 15 30 / 37%) 44%, rgb(0 15 30 / 67%) 100%);
    }

    &:hover {
      color: $white;
    }

    &-image {
      transform: scale(1);

      display: block;

      width: 100%;
      height: 100%;

      object-fit: cover;
      background-color: #222;

      image-rendering: optimize-contrast;

      transition: transform 0.8s cubic-bezier(0.35, 0, 0, 1);
    }

    &:hover {
      .news-releases__item-image {
        transform: scale(1.05);
      }
    }
  }

  &__intro {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    left: 0;

    padding: 1em;
  }
}
