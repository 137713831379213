/* Theme color style variable */
$white: #fff !default;
$black: #000 !default;
$athens-gray: #fafbfc !default;
$limed-spruce: #333e48 !default;
$shark: #202124 !default;
$robins-egg-blue: #00c6d1 !default;
$bondi-blue: #0091bd !default;
$eastern-blue: #1191bd !default;
$supernova: #ffc600 !default;
$porcelain: #e5eceb !default;
$river-bed: #46535f !default;
$limed-spruce: #343e47 !default;
$gray-chateau: #a3a8ae !default;

/* Bootstrap settings */
$theme-colors: (
  'primary':            #12809f,
  'info':               $bondi-blue,
  'dark':               #333e48,
  'secondary':          $shark,
  'warning':            $supernova,
  'success':            #208754,
  'light':              #f2f2f2,
  'white':              $white,
  'danger':             #dc3545
);
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
  sticky-100: 100px
);
$colors: (
  'yellow': $supernova,
);

/* Check all Bootstrap variables in file /node_modules/bootstrap/scss/_variables.scss */
$form-check-input-checked-bg-color: $supernova !default;
$link-color: $black !default;
$link-hover-color: $black !default;
$nav-pills-link-active-bg: $link-color !default;
$breadcrumb-divider-color: $white !default;
$nav-link-color: $black !default;
